<template>
	<v-container v-if="!this.$store.getters.is_authenticated">{{ $t("system.login_required") }}</v-container>
	<v-container fluid v-else>
		<v-container>
		<!-- <h1 class="centrovane">{{ $t('rma_overview.overview') }}</h1> -->
		<v-card>
			<v-card-title>{{ $t('rma.overview') }}</v-card-title>
			<v-card-text>
				<v-btn block router to="/rmaNew" class="mr-4">{{$t('rma.btn_new')}}</v-btn>
				<v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('rma.search')" single-line hide-details></v-text-field>
				<br>
				<v-data-table :loading="is_loading" :loading-text="$t('rma.loading')" dense :headers="headers" :items="rma_data" :search="search"></v-data-table>
			</v-card-text>
		</v-card>
	</v-container>
	</v-container>
</template>

<script>
import ApiService from "@/services/api_service"

export default {
	data() {
		return {
			search: "",
			is_loading: true,
			headers: [
				{ text: this.$i18n.t("rma.uid"), value: "uid",	align: "start" },
				{ text: this.$i18n.t("rma.submission_date"), value: "submission_date"},
				{ text: this.$i18n.t("rma.pl_code"), value: "pl_code" },
				{ text: this.$i18n.t("rma.serial_number"), value: "serial_number"},
				{ text: this.$i18n.t("rma.fault_description"), value: "fault_description" },
				{ text: this.$i18n.t("rma.status"), value: "status" },
			],
			rma_data: []
		};
	},
	mounted() {
		this.current_user_id = this.$store.getters.get_user_id
		ApiService.get('/rma/overview/' + this.current_user_id).then(response => {
			this.rma_data = response.data
			for(var i = 0; i < this.rma_data.length; i++) {
				if(this.rma_data[i]['status'] == '0_created') {
					this.rma_data[i]['status'] = this.$i18n.t('rma.status_created')
				}
				else if(this.rma_data[i]['status'] == '1_inProgress') {
					this.rma_data[i]['status'] = this.$i18n.t('rma.status_in_progress')
				}
				else if(this.rma_data[i]['status'] == '2_done') {
					this.rma_data[i]['status'] = this.$i18n.t('rma.status_done')
				}
			}
			this.is_loading = false
		}).catch(() => {})
	}
};
</script>

<style scoped>
.centrovane {
	text-align: center;
	display: block;
}
</style>
